<template>
    <Button @click="openCreateEanModal = true">{{ $tc('create_ean', 1) }}</Button>

    <section>
        <table class="min-w-full divide-y divide-fibonaki-border">
            <thead>
                <tr>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('id', 1) }}</th>
                    <th scope="col" class="px-4 py-3 text-left text-sm font-medium text-gray-900">{{ $tc('number', 1) }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="ean in eans" :key="ean">
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ ean.id }}</td>
                    <td class="px-4 py-3 whitespace-nowrap text-sm text-gray-500">{{ ean.ean_code }}</td>
                </tr>
            </tbody>
        </table>
    </section>

    <Modal :title="$tc('create_inverter', 1)" :show="openCreateInverterModal" @submit="createInverter" @close="openCreateInverterModal = false">
        <div class="grid grid-cols-12 gap-x-6 gap-y-3">
            <div class="col-span-12">
                <Input :label="$tc('ean_code', 1)" type="text" id="ean_code" v-model="ean.ean_code" />
            </div>

            <div class="col-span-12">
                <Input :label="$tc('country', 1)" type="text" id="country" v-model="ean.country" />
            </div>
            <div class="col-span-6">
                <Input :label="$tc('zip', 1)" type="text" id="zip" v-model="ean.zip" />
            </div>
            <div class="col-span-6">
                <Input :label="$tc('city', 1)" type="text" id="city" v-model="ean.city" />
            </div>
            <div class="col-span-12">
                <Input :label="$tc('street', 1)" type="text" id="street" v-model="ean.street" />
            </div>
            <div class="col-span-6">
                <Input :label="$tc('number', 1)" type="text" id="number" v-model="ean.number" />
            </div>
            <div class="col-span-6">
                <Input :label="$tc('addition', 1)" type="text" id="addition" v-model="ean.addition" />
            </div>
        </div>
    </Modal>
</template>

<script>
    export default {
        created() {
            this.$store.dispatch('clients/fetchInverters', this.$route.params.client_id);
        },
        computed: {
            inverters() {
                return this.$store.getters['clients/inverters'];
            },
        },
        data() {
            return {
                inverter: {},
                openCreateInverterModal: true,
            };
        },
        methods: {
            async createInverter() {
                await this.$store.dispatch('clients/createInverter', { client_id: this.$route.params.client_id, inverter: this.inverter });
                this.openCreateInverterModal = false;
                this.inverter = {};
            },
        },
    };
</script>
